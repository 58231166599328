<template>
  <!-- 微信提现到零钱 -->
  <!-- <div class="withdrawal-max-wrap">
    <div class="header dfr cross-center">
      <div class="left">
        <img class="w100 h100" src="../assets/images/wechat.png" alt="" />
      </div>
      <div class="right ">
        <div class="fs32 ">提现至微信零钱</div>
        <div class="fs24 fc999 desc">请至微信钱包查收</div>
      </div>
    </div>
    <div class="price-wrap">
      <div class="whitdrawal-price-box por  dfr cross-center">
        <div class="unit">￥</div>
        <div class="price-input-box ">
          <van-field
            @input="priceInputChange"
            type="digit"
            class="weui-input"
            clearable
            v-model="whithdrawalPrice"
            placeholder="输入提现金额"
          />
        </div>
      </div>
      <div class="can-price-count fc999 fs28">可提现余额 {{ price }}元</div>
    </div>
    <div class="submin-wrap">
      <div
        @click="withdrawal"
        class=" submit dfr flex-center fs32 fc333 "
        :class="[canWithdrawal ? '' : 'op5']"
      >
        提交
      </div>
    </div>
  </div> -->
  <div class="withdrawal-max-wrap">
    <div class="tip fctheme fs24">
      提交申请后，我们将在2个工作日内完成转账处理
    </div>
    <div class="cell-box">
      <div class="cell">
        <div class="name">账户名</div>
        <van-field
          @input="nameInputChange"
          type="text"
          class="default-input"
          clearable
          v-model="name"
          placeholder="请输入您的银行卡账户名"
        />
      </div>
      <div class="cell">
        <div class="name">银行卡号</div>
        <van-field
          @input="bankNumInputChange"
          type="text"
          class="default-input"
          clearable
          v-model="bankNum"
          placeholder="请输入您的银行卡号"
        />
      </div>
      <div class="cell">
        <div class="name">所属银行</div>
        <van-field
          @input="bankInputChange"
          type="text"
          class="default-input"
          clearable
          v-model="bank"
          placeholder="请输入您的银行卡所属银行"
        />
      </div>
    </div>
    <div class="price-wrap">
      <!-- 提现金额 -->
      <div class="whitdrawal-price-box por  dfr cross-center">
        <div class="unit">￥</div>
        <div class="price-input-box fs28">
          <van-field
            class="price "
            @input="priceInputChange"
            type="number"
            :max="price"
            :adjust-position="false"
            clearable
            v-model.trim="whithdrawalPrice"
            placeholder="输入提现金额"
          />
        </div>
      </div>
      <!-- 可提现余额 -->
      <div class="can-price-count fc999 fs28">
        可提现最大金额为 {{ price }} (1元=={{
          1 / this.$config.pointRMBRate
        }}积分)
      </div>
    </div>
    <!-- 提现 -->
    <!-- <div class="submin-wrap">
      <div
        v-if="isFriday"
        @click="withdrawal"
        class=" submit dfr flex-center fs32 fc333 "
        :class="[canWithdrawal ? '' : 'op5']"
      >
        提交
      </div>
      <div v-else class=" submit dfr flex-center fs32 fc333 op5">
        仅周五可以提交提现申请
      </div>
    </div> -->
    <div class="b-button-box bgfff">
      <div class="bottom-charge dfc flex-center">
        <icy-button
          @click.native="withdrawal"
          :class="[canWithdrawal ? '' : 'op5']"
          >提交</icy-button
        >
      </div>
    </div>
  </div>
</template>

<script>
// 微信提现到零钱
/**
import { homeShare } from "../utils/util";
export default {
  props: {},
  data() {
    return {
      price: 0,
      whithdrawalPrice: "",
      canWithdrawal: false
    };
  },
  created() {
    homeShare();
    this.$title("提交提现");
    const userInfo = this.$localStore.get("userInfo");
    this.price = userInfo.amount;
  },
  mounted() {},
  methods: {
    priceInputChange() {
      if (this.whithdrawalPrice > this.price) {
        this.whithdrawalPrice = this.price;
      }
      this.canWithdrawalHandle();
    },
    // 提现
    async withdrawal() {
      if (this.canWithdrawal) {
        const data = {
          amount: this.whithdrawalPrice
        };
        const resp = await this.$API
          .post("withdrawal", data)
          .then(resp => resp);
        if (resp.code == 1000) {
          this.$toast("提现申请已提交");
          this.$goReplace("withdrawalHistory");
        }
      }
    },
    // 显示可以提交
    canWithdrawalHandle() {
      if (this.whithdrawalPrice && this.price) {
        this.canWithdrawal = true;
      } else {
        this.canWithdrawal = false;
      }
    }
  },
  destroyed() {}
};
*/
// 提现到银行卡
// import { homeShare } from "../../utils/util";
// import dayjs from "dayjs";
export default {
  props: {},
  data() {
    return {
      price: 0,
      nameIconShow: false,
      bankNumIconShow: false,
      bankIconShow: false,
      priceIconShow: false,
      name: "",
      bankNum: "",
      bank: "",
      whithdrawalPrice: "",
      canWithdrawal: false,
      isFriday: false
    };
  },
  created() {
    // homeShare();
    this.$title("申请提现");
    const withdrawalInfo = this.$localStore.get("withdrawalInfo");
    if (withdrawalInfo) {
      this.name = withdrawalInfo.name;
      this.bankNum = withdrawalInfo.bankNum;
      this.bank = withdrawalInfo.bank;
    }

    this.initUserInfo();
    this.initDay();
  },
  mounted() {},
  methods: {
    initDay() {
      // let isFriday = false;
      // let weekday = dayjs().weekday();
      // 5 是周五
      // if (weekday == 5) {
      //   isFriday = true;
      // }
      // this.isFriday = isFriday;
    },
    async initUserInfo() {
      const resp = await this.$API.post("userInfo").then(resp => resp);
      if (resp.code == 1000) {
        this.price =
          parseFloat(resp.data.info.balance) / (1 / this.$config.pointRMBRate);
      }
    },
    priceInputChange() {
      if (this.whithdrawalPrice > this.price) {
        this.whithdrawalPrice = this.price;
      }
      this.canWithdrawalHandle();
    },
    nameInputChange() {
      this.canWithdrawalHandle();
    },
    bankNumInputChange() {
      this.canWithdrawalHandle();
    },
    bankInputChange() {
      this.canWithdrawalHandle();
    },
    // 提现
    async withdrawal() {
      if (this.canWithdrawal) {
        const data = {
          price: this.whithdrawalPrice,
          accountName: this.name,
          cardNumber: this.bankNum,
          bank: this.bank
        };
        const resp = await this.$API
          .post("withdrawal", data)
          .then(resp => resp);
        if (resp.code == 1000) {
          this.$toast("提现申请已提交");
          this.$goReplace("withdrawalHistory");
        }
      }
    },
    // 显示可以提交
    canWithdrawalHandle() {
      if (
        this.name &&
        this.bankNum &&
        this.bank &&
        this.whithdrawalPrice &&
        this.price
      ) {
        this.canWithdrawal = true;
      } else {
        this.canWithdrawal = false;
      }
      const withdrawalInfo = {
        name: this.name,
        bankNum: this.bankNum,
        bank: this.bank
      };
      this.$localStore.set("withdrawalInfo", withdrawalInfo);
    }
  },
  destroyed() {}
};
</script>
<style scoped lang="less">
/* 微信提现到零钱 */
/**
  .withdrawal-max-wrap {
    min-height: 100vh;
    overflow: hidden;
    background: #f9f9f9;
  }
  .header {
    margin-top: 0.2rem;
    border-top: 0.01rem solid #eee;
    background: #fff;
    border-bottom: 0.01rem solid #eee;
    padding: 0.34rem 0.28rem;
    box-sizing: border-box;
  }
  .header .right .desc {
    margin-top: 0.05rem;
  }
  .header .left {
    width: 0.6rem;
    height: 0.6rem;
    font-size: 0;
    margin-right: 0.28rem;
  }
  .price-wrap {
    background: #fff;
    margin-top: 0.2rem;
  }
  .input-placeholder {
    font-size: 0.28rem;
    line-height: 1;
  }
  .close-box {
    width: 0.24rem;
    height: 0.24rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0.28rem;
    z-index: 2;
  }
  .close-box::after {
    content: "";
    display: block;
    width: 150%;
    height: 150%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .cell .name {
    width: 1.52rem;
  }
  .cell .weui-input {
    width: 5rem;
    box-sizing: border-box;
    overflow: hidden;
    min-height: auto;
  }
  .can-price-count {
    padding: 0.3rem 0.28rem;
    border-top: 0.01rem solid #eee;
    border-bottom: 0.01rem solid #eee;
  }
  .whitdrawal-price-box {
    padding: 0.56rem 0.28rem;
    border-top: 0.01rem solid #eee;
  }
  .unit {
    font-size: 0.48rem;
    color: #333;
    margin: 0 0.28rem 0 0;
    line-height: 1;
  }

  .submin-wrap {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1.2rem;
    height: calc(1.2rem + env(safe-area-inset-bottom));
    box-sizing: border-box;
    padding: 0.2rem 0.36rem;
    background: #fff;
    border-top: 0.01rem solid #eee;
  }
  .submit {
    width: 6.7rem;
    height: 0.8rem;
    background: linear-gradient(
      90deg,
      rgba(250, 190, 0, 1) 0%,
      rgba(250, 134, 0, 1) 100%
    );
    box-shadow: 0px 0.04rem 0.08rem 0px rgba(250, 190, 0, 0.4);
    border-radius: 0.4rem;
    box-sizing: border-box;
  }
  .submit:active {
    opacity: 0.5;
  }
*/

.b-button-box {
  height: calc(1.2rem + env(safe-area-inset-bottom));
  height: calc(1.2rem + constant(safe-area-inset-bottom));
  .bottom-charge {
    padding: 0.2rem 0.36rem;
    padding-bottom: calc(0.2rem + env(safe-area-inset-bottom));
    padding-bottom: calc(0.2rem + constant(safe-area-inset-bottom));
    width: 7.5rem;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
/* 提现到银行卡 */
.withdrawal-max-wrap {
  height: 100vh;
  overflow-y: auto;
  background: #f7f7f7;
}
.tip {
  padding: 0.18rem 0.28rem;
  border-top: 0.01rem solid #eee;
  border-bottom: 0.01rem solid #eee;
}
.cell-box {
  background: #fff;
  padding-left: 0.28rem;
}
.cell {
  padding: 2px 0;
  display: flex;
  align-items: center;
  // padding: 0.3rem 0;
  position: relative;
  border-bottom: 0.01rem solid #eee;
  color: #333;
  font-size: 0.28rem;
}
.cell:last-child {
  border: none;
}
.price-wrap {
  background: #fff;
  margin-top: 0.16rem;
}
.input-placeholder {
  font-size: 0.28rem;
  /* height: 44rem; */
  line-height: 1;
}
.close-box {
  width: 0.24rem;
  height: 0.24rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0.28rem;
  z-index: 2;
}
.close-box::after {
  content: "";
  display: block;
  width: 150%;
  height: 150%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.cell .name {
  width: 1.52rem;
}
.cell .weui-input {
  width: 5rem;
  /* height: 44rem; */
  box-sizing: border-box;
  overflow: hidden;
  min-height: auto;
}
.can-price-count {
  padding: 0.3rem 0.28rem;
  border-top: 0.01rem solid #eee;
  border-bottom: 0.01rem solid #eee;
}
.whitdrawal-price-box {
  padding: 0.2rem 0.28rem;
  border-top: 0.01rem solid #eee;
}
.unit {
  font-size: 0.48rem;
  color: #333;
  margin-right: 0.28rem;
}
.price-input-box .weui-input {
  font-size: 0.8rem;
}

.submin-wrap {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1.2rem;
  box-sizing: border-box;
  padding: 0.2rem 0.36rem;
  background: #fff;
  border-top: 0.01rem solid #eee;
}
.submit {
  width: 6.7rem;
  height: 0.8rem;
  background: linear-gradient(
    90deg,
    rgba(250, 190, 0, 1) 0%,
    rgba(250, 134, 0, 1) 100%
  );
  box-shadow: 0px 0.04rem 0.08rem 0px rgba(250, 190, 0, 0.4);
  border-radius: 0.4rem;
  box-sizing: border-box;
}
.submit:active {
  opacity: 0.5;
}
</style>
